import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
  name: 'trustVideo'
})
export class TrustVideoPipe implements PipeTransform {
  safeSrc: SafeResourceUrl;
  constructor(private domSanitizer: DomSanitizer) {}
  transform(url: string) {
    if(url.includes('https://www.youtube.com')){
      const indexId: number = url.indexOf('=');
      const idVideo = url.slice(indexId + 1, url.length);
      url = `https://www.youtube.com/embed/${idVideo}`;
    }
    this.safeSrc = this.domSanitizer.bypassSecurityTrustResourceUrl(url);
    return this.safeSrc;
  }

}
